/* FIXES
--------------------------------------------------------------------------------------------*/


//FORMS

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 80vh !important;
}

.ac_hero_slide_content_text_desciption {
    p {
        font-size: 50px;
        font-weight: bold;
        text-shadow: 2px 2px #444444;
    }
}
.ac_hero_slide_content_text {
    a  {
        color:$brand-white !important;
    }
}

#opzegging-sectie-titel-3-3 .ac_content_container .ac_content_flex  {
    display:block !important;
}

//HEADERS

[data-s-type="hero-alt"] {
    position: relative;
    background: linear-gradient(
-135deg
,$brand-primary-darker, $brand-primary) !important;
    background-image: linear-gradient(
-135deg
, rgb(55, 0, 140), rgb(27, 123, 207)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;

}

.ac_menu-2_logo_container{
    .ac_menu-2_logo_img {
        margin-top:-55px !important;
        max-width: 150% !important;
    }
}

.ac_menu-2_logo_img {
    width: 220px;
}

.ac_menu-2_mobile_logo_img {
    display:none;
}
.ac_menu-2_logo_img {
    display: block;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 200px;
    height: auto;
    margin-top:-5px !important;
}

.ac_menu-2_bar_top {
    background: none;
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        //height: 80vh !important;
    }
    
}

.button.v_has-icon-left .svg-container {
    margin-right: 10px;
}

.button.v_has-icon-right .svg-container {
    margin-left: 10px;
}
[data-m-type="menu-2"] a {
    font-size: 11pt !important;
}
[data-m-type="menu-2"] a.button {
    font-size: 16pt !important;
    margin-right:12px;
    padding:0.4em;
}

[data-m-type="menu-2"] a:hover {
    
    color:$brand-primary;
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: flex-start;
}


.ac_menu-2_cta_link {
    font-size: 20px !important;
}

[data-m-type="menu-2"] {
    a {
        color:$brand-black;
        font-weight: 600;
        font-size: 13px;
    }
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:200px
}

#breadcrumbs {
    display: none;
}

.ac_menu-2_btn-search svg {
    width: 30px;
    height: 30px;
    margin-top: -13px;
}

//Menus
.ac_menu-2_bar_top_container {
    justify-content: right;
    a {
        font-weight: 600;
    }
}

.ac_menu-2_bar_bottom_container {
    justify-content: right !important;
    a {
        font-weight: 600;
    }
}

.ac_menu-2_bar_top {
    background: none;
    a {
        color:$brand-secondary-darker !important;
    }
}

.button.v_transparent_body {
    color:$brand-tertiary;
    font-weight: 600;
}

.ac_hero_slide {
    height:80vh;
}

.ac_hero_alt_slide {
   height:125px;
}
[data-s-type="hero"] .ac_hero_container {
    height:100% !important;
}

.slick-dots {
    bottom:40px !important;
}

.button:hover {
    color:#fff;
}

[data-s-amount="2"] .slick-slide [data-s-amount-item]{
    max-width: initial !important;
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:125px;
    min-height: 125px;
}

[data-s-type="hero-alt"] h1, [data-s-type="hero-alt"] h2, [data-s-type="hero-alt"] h3 {
    padding-top:20px;
}

.ac_hero_slide_content_text {
    display: block;
    h2 {
        font-size: 34px !important;
        font-weight: bold;
        text-shadow: 2px 2px #5a5a5a;
        text-align: center;
    }
}


@media screen and (max-width: 1100px){
    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }

    .ac_menu-2_logo_img {
        width: 200px !important;
        height: auto;
        margin-top:10px !important;
    }
}

.ac_menu-2_logo_container + .ac_menu-2_main_content{
    margin-left: auto;
    margin-right: 5px;
}


// MENU MOBILE
@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    .ac_menu-2_mobile_logo_img {
        display:block;
        max-height: 45px;
        margin-top:0px !important;
    }
    .ac_menu-2_logo_img {
        display: none;
    }

    .ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
        width: 150px !important;
        height: auto;
        margin-top:10px !important;
    }


    .ac_menu-2_main_content {
        padding-left:0px !important;
        margin-left:0px !important;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

h2, h3, h4 {
    color: $brand-primary;
    padding-bottom:10px;
}

// GENERAL
.home {
    h2 {
        color:$brand-secondary-darker;
        text-decoration: underline;
        text-underline-offset: 3px;
        text-decoration-color: $brand-primary;
    }
}

.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

.button {
    background: $brand-primary-darker;
}

body {
    color:$brand-black;
}

//NEWS

[data-s-id="home-news"] .ac_item_image-container {
    display: none;
}
[data-s-type="news"] .ac_item_content_title {
    color: $brand-black;
}


// BRANDS

.ac_brands_item_container {
    border: none !important;
}

.ac_brands_item {
    border: none !important;
}

// SERVICES
[data-s-type="services"] .ac_container {
    flex-direction: row;
    flex-wrap:wrap;
    align-content: space-around;
    justify-content: center;
    align-items: normal;
}

[data-s-id="home-services"] {
    padding-bottom: 0px;
    h2 {
        color:$brand-white;
        text-decoration: underline;
        text-underline-offset: 3px;
    }
}

[data-s-type="services"] .ac_item {
    height: 240px;
    width: 230px;
}

[data-s-type="services"] .ac_item_content {
    top:-30px;
}

[data-s-type="services"] .ac_item_content_icon {
    background: none;
}

[data-s-type="services"] .ac_item:hover {
    .ac_item_content_icon {
        background: none !important;
    }
}

//FOOTER
[data-f-type="footer-1"] h2 {
    font-size: 160%;
    margin-bottom: 0px;
    opacity: .5;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-black;
}

[data-f-type="footer-1"] .ac_footer_primary_container {
    padding: 20px 10px 5px 10px;
}

@media screen and ( max-width: 800px ){

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        margin-left: -12px;
    }
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-black;
    border-top:2px solid $brand-light-gray;

    .ac_footer_primary_column{
        flex: 1 !important;
        max-width: initial !important;
    }

    .alignnone{
        width: 90%;
        float: right;
        margin: -100px 0 0 0;
    }
}


.ac_footer_primary_container {
    padding-top:40px !important;
    padding-bottom:20px !important;
}

//CTA
.ac_cta_column {
    p {
        font-size: 18px;
        font-weight: 600;
        color: $brand-white;
    }
    .button {
        margin-left:50px;
    }
}

.ac_cta_column {
    h2 {
        color:$brand-secondary-darker;
    }
    .button {
        margin-bottom:5px !important;
    }
}

[data-s-type="cta"] {
    padding:0 !important;
}

// IMAGES OVERLAY
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}

li::marker {
    color:$brand-primary;
}

.ac_content_1-1 {
    padding-left: 10px;
    padding-right: 10px;
}

//brands
.ac_brands_item_logo {
    -webkit-filter: none !important;
    filter: none !important;
}

//toptasks
.ac_toptasks_item {
    .ac_toptasks_item_icon svg {
        stroke:$brand-secondary-darker;
    }
    .button {
        background-color: $brand-tertiary-darker;
    }
}

.ac_toptasks_item:hover {
    .ac_toptasks_item_icon {
        background: $brand-white;
        transition: all 0.3s ease;
    }

    .ac_toptasks_item_content_title {
        color:$brand-secondary-darker;
    }
    .ac_toptasks_item_content_description {
        color:$brand-primary;
    }
 
    .ac_toptasks_item_icon svg {
        stroke:$brand-primary;
    }

    .button {
        background-color: $brand-primary-darker;
    }
}



.ac_toptasks_item_image-container {
    background: none;
}

.ac_toptasks_item_icon-container {
    position: relative;
    z-index: 1;
    margin-bottom: 10px;
}
.ac_toptasks_item_icon svg {
    width: 70px;
    height: 70px;
    transition: all 0.3s ease;
    stroke: #646463;
    fill: transparent;
}
.ac_toptasks_item_icon {
    width: 80px;
    height: 80px;
    background: none;
    padding: 6px;
    border-radius: 100%;
    transition: all 0.3s ease;
}

.ac_toptasks_item_content {
    .ac_toptasks_item_content_title {
        color:$brand-secondary-darker;
    }
    .ac_toptasks_item_content_description {
        color:$brand-primary;
    }
}
// REFERENCES
[data-s-type="references"] .ac_item_image {
    opacity: 1;
}

[data-s-type="references"] .ac_item_image-container:before {
    background: none !important;
}

.ac_image-gallery_item_image_container {
    height:280px !important;
}